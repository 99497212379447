<template>
	<section
		:id="slug"
		:class="{
			'mobile': mobileMode,
			'desktop': !mobileMode,
		}"
		class="our-work-page"
	>
		<header class="page-header">
			<h1 class="page-title" v-html="title" />
		</header>
		<grid-layout
			:tiles="tiles"
			:custom-columns-count="4"
			class="top-projrects"
		/>
		<template v-if="mobileMode">
			<all-projects-card />
			<pdf-download-card v-if="pdfCardData.url"
				:card-data="pdfCardData"
			/>
		</template>
	</section>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import subpageMixin from './subpage-mixin';

import GridLayout from '@/components/grid-layout.vue';
import AllProjectsCard from '@/components/all-projects-card.vue';
import PdfDownloadCard from '@/components/pdf-download-card.vue';

function getProjectDate(project) {
	if (!project || !project.project_time || !project.project_time.length) return 0;
	return Math.max(...project.project_time.map(timeList => new Date(timeList.time)));
}


const ALL_PROJECTS_CARD = Object.freeze({
	type: 'all-projects-card',
	data: {
		slug: 'ALL-PROJECTS',
		cell: { w: 1, h: 1 },
	},
});


const PDF_DOWNLOAD_CARD = Object.freeze({
	type: 'pdf-download-card',
	data: {
		slug: 'PDF-DOWNLOAD',
		cell: { w: 1, h: 1 },
	},
});


export default {
	mixins: [
		// adds `pageData` prop and `page` computed value
		subpageMixin,
	],


	name: 'OurWork',


	components: {
		GridLayout,
		AllProjectsCard,
		PdfDownloadCard,
	},


	props: {
		// `pageData` via subpage mixin
	},


	data() {
		return {
			limits: {
				desktop: 4,
				mobile: 4,
			},
		};
	},


	computed: {
		// [page, slug] via subpage mixin
		...mapState('projects', { projects: 'list' }),

		pdfCardData() {
			let mixin = {};
			if (this.page.file_link) {
				mixin = { url: this.page.file_link };
			}

			if (this.page.pdf_file && this.page.pdf_file.file) {
				mixin = {
					url: this.page.pdf_file.file,
					size: this.page.pdf_file.file_size,
				};
			}

			return {
				...PDF_DOWNLOAD_CARD.data,
				...mixin,
			};
		},

		tiles() {
			// fix cells for top projects
			const cellsFix = [
				{ w: 2, h: 1 },
				{ w: 2, h: 1 },
				{ w: 1, h: 1 },
				{ w: 1, h: 1 },
			];

			const tiles = this.projects
			.filter(project => project.on_main)
			.slice(0, this.limits[this.mobileMode ? 'mobile' : 'desktop'])
			.sort((projectA, projectB) => getProjectDate(projectB) - getProjectDate(projectA))
			.map((project, index) => ({
				type: 'project-card',
				data: {
					...project,
					cell: cellsFix[index],
				},
			}));

			if (!this.mobileMode) {
				tiles.push(ALL_PROJECTS_CARD);
				if (this.pdfCardData.url) {
					tiles.push({ ...PDF_DOWNLOAD_CARD, data: this.pdfCardData });
				}
			}

			return tiles;
		},

	},


	methods: {
		...mapActions('projects', ['getProjects']),
	},


	mounted() {
		if (this.projects.length === 0) {
			this.getProjects();
		}
	},
};
</script>


<style lang="sass" scoped>
.our-work-page
	@extend %page
	align-items: stretch

	&.mobile
		@extend %page-mobile
		padding-left: 0
		padding-right: 0

		&.standalone
			@extend %page-mobile-standalone

	&.desktop
		@extend %page-desktop
		padding-left: 0
		padding-right: 0

		&.standalone
			@extend %page-desktop-standalone

.page-header
	.mobile &
		@extend %page-header-mobile
		padding-left: 25px
		padding-right: 25px

	.mobile.standalone &
		@extend %page-header-mobile-standalone

	.desktop &
		@extend %page-header-desktop
		padding-left: 40px
		padding-right: 40px

	.desktop.standalone &
		@extend %page-header-desktop-standalone

.page-title
	@extend %page-title

	.mobile &
		@extend %page-title-mobile

	.desktop &
		@extend %page-title-desktop

.top-projrects
	width: 100%
</style>
